<template>
    <div class="modal-user-detail" :class="{ 'more-p-b': showBottomButtons }" @scroll="onScroll">
        <div class="header-bar flex-row">
            <transition name="slide-down">
                <div v-show="showHiddenHeader" class="title" v-html="title"></div>
            </transition>
            <div @click="$router.go(-1)" class="left flex-row items-center flex-wrap">
                <i class="zmdi zmdi-chevron-left flex-wrap" :class="{ 'c-white': !showHiddenHeader }" />
            </div>
            <div class="right flex-row items-center flex-wrap" @click="onClickMore">
                <i class="material-icons" :class="{ 'c-white': !showHiddenHeader }">more_horiz</i>
            </div>
        </div>
        <UserDetailPremiumComment v-if="isPremiumAgent && hasPremiumInfos" :user="user" />
        <Carousel
            :per-page="1"
            :pagination-position="'bottom-overlay'"
            :pagination-size="8"
            :pagination-padding="4"
            :pagination-active-color="'#FFFFFF'"
            :pagination-color="'#ADACB0'"
        >
            <Slide class="p-relative" :key="photo.id" v-for="photo in user.photos">
                <div class="overlay top"></div>
                <div class="img-cover" v-img-cover="photo.url" />
                <div class="overlay bottom"></div>
            </Slide>
        </Carousel>
        <div class="body">
            <div class="section">
                <div v-if="user.name">
                    <span class="name">
                        <b>{{ user.name }}</b
                        >님
                    </span>
                    <span> ({{ user.profile.nickname }}) </span>
                </div>
                <div v-else>
                    <span class="name">
                        <b>{{ user.profile.nickname }}</b
                        >님
                    </span>
                </div>
                <div class="basic-info">
                    <span class="info" v-html="`${$options.filters.asAge(user.profile.birthday)}세`" />
                    <span class="divider" v-html="'ㅣ'" />
                    <span class="info" v-html="`${user.profile.height}cm`" />
                    <!-- <span class="divider" v-html="'ㅣ'"/> -->
                    <!-- <span v-html="user.profile.region_name"/> -->
                </div>
                <div class="region-info">
                    <span v-html="user.profile.region_name" />
                    <Distance v-if="user.$$distance" class="flex-wrap m-l-8" :distance="user.$$distance" />
                    <!-- 거리에 따라 우는 아이콘 추가-->
                </div>
                <!-- <NoOnsCampaignBadge :user="user" /> -->
                <!--                <div class="no-ons-campaign" v-if="user.profile.no_ons_campaign === 1">-->
                <!--                    <span>#Goodbye_To_ONS 캠페인 참여</span>-->
                <!--                    <div class="circle m-l-5">-->
                <!--                        <i class="material-icons">close</i>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
            <div class="hr" v-if="!$isTester()" />
            <div class="section">
                <div class="title" v-html="'기본 정보'" />
                <div class="detail" :key="idx" v-for="(section, idx) in profile">
                    <div :key="idx" v-for="(item, idx) in section.items">
                        <div class="school flex-row items-center">
                            <i class="zmdi zmdi-city flex-wrap" v-if="!user.profile.is_student" />
                            <div class="c-text-dark flex-wrap">학사 : {{ user.profile.bachelor_shorten }}</div>
                        </div>
                        <div class="school flex-row items-center">
                            <i class="zmdi zmdi-city flex-wrap" v-if="!user.profile.is_student" />
                            <div class="c-text-dark flex-wrap">석사 : {{ user.profile.master_shorten }}</div>
                        </div>
                        <div class="school flex-row items-center">
                            <i class="zmdi zmdi-city flex-wrap" v-if="!user.profile.is_student" />
                            <div class="c-text-dark flex-wrap">박사 : {{ user.profile.doctor_shorten }}</div>
                        </div>
                        <div v-if="user.profile[item.key] || item.alt" class="row">
                            <span class="key">{{ item.key.toUpperCase() | translate }}</span>
                            <span v-if="user.profile[item.key]" class="value">{{ user.profile[item.key] }}</span>
                            <span v-else>{{ item.alt }}</span>
                            <span v-if="item.postfix" class="postfix">{{ item.postfix }}</span>

                            <Mbti
                                v-if="item.key === 'mbti'"
                                :user-mbti="(chatUser.profile || {}).mbti || ''"
                                :target-mbti="(user.profile || {}).mbti || ''"
                            />
                            <span class="badge" v-if="item.badge" v-html="item.badge" />
                        </div>
                        <div class="job-description" v-if="item.description">
                            <div :class="{ 'lines-2': shouldClamp }" ref="agentMessage" v-html="item.description" />
                            <div v-if="shouldClamp" class="c-primary btn-more f-medium" @click="shouldClamp = false">
                                더 보기
                            </div>
                        </div>
                    </div>
                    <div class="hr" />
                </div>
            </div>
            <div v-if="intro" class="hr" />
            <div v-if="intro" class="section">
                <div class="user-intro">
                    <div class="title" v-html="$translate('SELF_INTRODUCE')" />
                    <div class="content" v-html="intro" />
                </div>
            </div>
            <div class="hr" />
            <div class="section">
                <UserStyles v-if="user.style" :user="user" />
            </div>
            <div class="hr" />
            <LikeTags :user="user" />
            <div class="hr" />
            <!-- <DatingStyles :user="user" /> -->
        </div>
        <BottomButtons v-if="user && showBottomButtons" :user="user" />
    </div>
</template>

<script>
import UserStyles from '@/components/common/UserStyles'
import LikeTags from '@/components/common/LikeTags'
import BottomButtons from '@/routes/mobile/user-detail/components/BottomButtons'
import Mbti from '@/components/app/Mbti'
import UserDetailPremiumComment from '@/routes/mobile/user-detail/components/UserDetailPremiumComment'
// import NoOnsCampaignBadge from '@/components/common/NoOnsCampaignBadge'

export default {
    name: 'ModalUserDetail',
    components: {
        UserStyles,
        LikeTags,
        BottomButtons,
        Mbti,
        UserDetailPremiumComment,
    },
    props: ['options'],
    data: () => ({
        user: null,
        showHiddenHeader: false,
        shouldClamp: true,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        chatUser() {
            return this.chat.user || {}
        },
        intro() {
            return this.user.profile.intro || ''
        },
        title() {
            if (!this.user) return

            const photoDom =
                (this.user.photos || []).length > 0 ? `<img class="img-profile" src="${this.user.photos[0].url}">` : ''

            return photoDom + this.$translate(`${this.user.profile.nickname}님의 프로필`)
        },
        profile() {
            if (!this.user) return

            const p = this.user.profile
            const badge = `<i class="zmdi zmdi-check"></i><span>${this.$translate('VERIFIED')}</span>`

            return [
                {
                    items: [
                        { key: 'job_name' },
                        {
                            key: 'company_name',
                            alt: p.job_category.name,
                            description: p.job_description,
                            badge: p.company_verified && badge,
                        },
                        {
                            key: 'university_name',
                            alt: p.school_type.name,
                            postfix: (p.is_student ? '재학' : '졸업') + ` (${p.school_type.name})`,
                            badge: p.university_verified && badge,
                        },
                        { key: 'major_name' },
                    ],
                },
                {
                    items: [
                        { key: 'income' },
                        { key: 'asset' },
                        { key: 'car_model' },
                        { key: 'finance' },
                        { key: 'hometown_shorten' },
                        { key: 'family_asset' },
                        { key: 'family_intro' },
                        { key: 'high_school_name' },
                    ],
                },
                {
                    items: [
                        { key: 'company_department' },
                        { key: 'company_task' },
                        { key: 'marry_plan' },
                        { key: 'child_plan' },
                        { key: 'career_plan' },
                    ],
                },
            ]
        },

        showBottomButtons() {
            return false
            // const from = this.$route.params.from

            // if (!from) return false

            // return from === 'InterestPage' || from === 'User' || from === 'ActionPhoto' || from === 'AcceptedUser'
        },
        agent() {
            return this.$store.getters.agent
        },
        isPremiumAgent() {
            return this.agent && this.agent.is_premium
        },
        hasPremiumInfos() {
            return false
            // return (this.user.premium_infos || []).length > 0
        },
    },
    methods: {
        async init() {
            // const userId = this.$route.params.userId
            const userId = this.options.userId
            if (!userId) {
                this.$router.go(-1)
                return
            }

            this.user = await this.$store.dispatch('loadUserDetail', { userId })
            this.user.profile.smoking = this.user.profile.smoking ? '흡연' : '비흡연'
            this.user.profile.car = this.user.profile.car === null ? '-' : this.user.profile.car ? '보유' : '미보유'
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > window.innerWidth - 56 // 56 is @header-height
        },
        onClickMore() {
            this.$actionSheet({
                buttons: [
                    {
                        label: '메모하기',
                        handler: () => {
                            if (this.$getChat(this.user.id)) {
                                this.$bus.$emit('toggleMemo', this.user)
                            } else {
                                this.$toast.error('친구만 가능합니다')
                            }
                        },
                    },
                    {
                        label: this.$translate('REPORT_USER'),
                        class: 'c-red',
                        handler: () => {
                            this.$router.push({
                                name: 'ReportUserPage',
                                params: { user: this.user },
                            })
                        },
                    },
                ],
            })
        },
    },
    mounted() {
        const dom = this.$refs.agentMessage
        if (dom) {
            this.shouldClamp = dom.scrollHeight - 1 > dom.clientHeight
        }

        this.init()
    },
}
</script>

<style lang="scss" scoped>
.modal-user-detail {
    max-height: 80vw;
    overflow-y: auto;
    padding-bottom: 40px;
    $bottom-button-height: 80px;

    &.more-p-b {
        padding-bottom: calc(40px + #{$bottom-button-height});
    }
    .header-bar {
        position: absolute;
        justify-content: space-between;
        left: 0;
        right: 0;
        z-index: 1;
        border-bottom: none;

        .title {
            position: absolute;
            height: $header-height;
            left: 0;
            right: 0;
            background: $white;
            border-bottom: 1px solid $grey-02;

            .img-profile {
                width: 32px;
                height: 32px;
                margin-right: 8px;
                border-radius: 40%;
            }
        }

        .left,
        .right {
            z-index: 1;
            position: absolute;
            top: 16px;

            .material-icons.c-white,
            .zmdi.c-white {
                color: white;
            }
        }

        .right {
            right: 0;
        }
    }

    .VueCarousel {
        margin-bottom: 8px;

        .overlay {
            height: $header-height;

            &.top {
                top: 0;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
            }

            &.bottom {
                top: initial;
                bottom: 0;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 95%);
            }
        }

        .img-cover {
            width: 100%;
            padding-top: 100%;
        }

        .VueCarousel-pagination {
            display: initial;
            bottom: 8px;
        }
    }

    .hr {
        width: 100%;
        height: 1px;
        background: $grey-02;
    }

    .section {
        padding: 24px 16px;
        .title {
            font-size: 18px;
            font-weight: 500;
            color: $black;
            font-family: NotoSans-Medium;
            margin-bottom: 24px;
        }
    }

    .user-intro {
        .content {
            white-space: pre-line;
            width: 100%;
            color: $grey-09;
            line-height: 24px;
        }
    }

    .name {
        margin-right: 4px;
        font-size: 20px;
        color: $black;
    }

    .basic-info {
        margin-top: 24px;
    }

    .region-info {
        margin-top: 4px;
        height: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .wealth-info {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;

        .wealth {
            width: fit-content;
            padding: 5px 10px;
            margin-right: 6px;
            height: 28px;
            border-radius: 14px;
            box-shadow: 0 0 6px 0 rgba(138, 116, 255, 0.2);
            border: solid 1px $purple-primary;
            background-color: $white;
            font-size: 13px;
            color: $black;

            .zmdi {
                color: $purple-primary;
                margin-right: 4px;
            }
        }
    }

    .detail {
        &:last-child {
            .hr {
                display: none;
            }
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        .zmdi-case,
        .zmdi-account {
            font-size: $icon-size;
            display: block;
            text-align: left;
            margin-bottom: 12px;
        }

        .row {
            margin-bottom: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $black;

            .key {
                width: 100px;
                margin-right: 0px;
                color: $grey-05;
            }

            .badge {
                color: $purple-primary !important;

                .zmdi {
                    margin-right: 4px;
                }
            }
        }

        .job-description {
            width: 246px;
            height: fit-content;
            font-size: 14px;

            margin-top: -12px;
            margin-bottom: 24px;
            margin-left: 22vw;
            padding: 12px;
            color: $grey-09;
            line-height: 22px;
            border-radius: 12px;
            border: solid 1px $grey-02;

            .btn-more {
                text-align: end;
                text-decoration: underline;
            }
        }
    }

    .styles {
        background: $grey-02;
    }

    .bottom-buttons {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: $bottom-button-height;
        background-color: white;
    }
}
</style>
